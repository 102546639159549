var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-6",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"d-flex justify-start align-center grey lighten-3"},[_c('div',{staticClass:"flex-grow-1"},[_vm._v("Litters")])]),_c('v-divider'),(_vm.dog.gender == 'F')?_c('v-data-table',{attrs:{"headers":_vm.littersHeaders,"items":_vm.dog.litters,"no-data-text":"There are no litters for this dog","items-per-page":-1},scopedSlots:_vm._u([{key:"item.dob",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.dob))+" ")]}},{key:"item.mating",fn:function({ item }){return [_vm._v(_vm._s(item.matings.length)+" ")]}},{key:"item.avg",fn:function({ item }){return [(item.matings.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.avgMatings(item))+" ")]):_c('div',[_vm._v("N/A")])]}},{key:"item.total_girls",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.countPuppiesGender("F", item).length)+" ")]}},{key:"item.total_boys",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.countPuppiesGender("M", item).length)+" ")]}},{key:"item.total_puppies",fn:function({ item }){return [_vm._v(" "+_vm._s(item.puppies.length)+" ")]}},{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                  name: 'module-lhl-litters-individual',
                  params: { litterId: item.id },
                }}},[_vm._v(_vm._s(item.name))])]}}],null,false,3256451044)}):_c('v-data-table',{attrs:{"headers":_vm.littersSireHeaders,"items":_vm.dog.litters_sire,"no-data-text":"There are no litters for this dog","items-per-page":-1},scopedSlots:_vm._u([{key:"item.dob",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.dob))+" ")]}},{key:"item.mating",fn:function({ item }){return [_vm._v(_vm._s(item.matings.length)+" ")]}},{key:"item.avg",fn:function({ item }){return [(item.matings.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.avgMatings(item))+" ")]):_c('div',[_vm._v("N/A")])]}},{key:"item.total_girls",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.countPuppiesGender("F", item).length)+" ")]}},{key:"item.total_boys",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.countPuppiesGender("M", item).length)+" ")]}},{key:"item.total_puppies",fn:function({ item }){return [_vm._v(" "+_vm._s(item.puppies.length)+" ")]}},{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                  name: 'module-lhl-litters-individual',
                  params: { litterId: item.id },
                }}},[_vm._v(_vm._s(item.name))])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }