<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Litters</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              v-if="dog.gender == 'F'"
              :headers="littersHeaders"
              :items="dog.litters"
              no-data-text="There are no litters for this dog"
              :items-per-page="-1"
            >
              <template v-slot:item.dob="{ item }">
                {{ formatDate(item.dob) }}
              </template>

              <template v-slot:item.mating="{ item }"
                >{{ item.matings.length }}
              </template>

              <template v-slot:item.avg="{ item }"
                ><div v-if="item.matings.length > 0">
                  {{ avgMatings(item) }}
                </div>
                <div v-else>N/A</div>
              </template>

              <template v-slot:item.total_girls="{ item }">
                {{ countPuppiesGender("F", item).length }}
              </template>

              <template v-slot:item.total_boys="{ item }">
                {{ countPuppiesGender("M", item).length }}
              </template>

              <template v-slot:item.total_puppies="{ item }">
                {{ item.puppies.length }}
              </template>

              <template v-slot:item.name="{ item }">
                <router-link
                  :to="{
                    name: 'module-lhl-litters-individual',
                    params: { litterId: item.id },
                  }"
                  >{{ item.name }}</router-link
                >
              </template>
            </v-data-table>

            <v-data-table
              v-else
              :headers="littersSireHeaders"
              :items="dog.litters_sire"
              no-data-text="There are no litters for this dog"
              :items-per-page="-1"
            >
              <template v-slot:item.dob="{ item }">
                {{ formatDate(item.dob) }}
              </template>

              <template v-slot:item.mating="{ item }"
                >{{ item.matings.length }}
              </template>

              <template v-slot:item.avg="{ item }"
                ><div v-if="item.matings.length > 0">
                  {{ avgMatings(item) }}
                </div>
                <div v-else>N/A</div>
              </template>

              <template v-slot:item.total_girls="{ item }">
                {{ countPuppiesGender("F", item).length }}
              </template>

              <template v-slot:item.total_boys="{ item }">
                {{ countPuppiesGender("M", item).length }}
              </template>

              <template v-slot:item.total_puppies="{ item }">
                {{ item.puppies.length }}
              </template>

              <template v-slot:item.name="{ item }">
                <router-link
                  :to="{
                    name: 'module-lhl-litters-individual',
                    params: { litterId: item.id },
                  }"
                  >{{ item.name }}</router-link
                >
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      littersHeaders: [
        { text: "Litter ID", value: "name" },
        { text: "Sire", value: "sire.name" },
        { text: "DoB", value: "dob" },
        { text: "Status", value: "status" },
        { text: "Puppies", value: "total_puppies" },
        { text: "Girls", value: "total_girls" },
        { text: "Boys", value: "total_boys" },
        { text: "Matings", value: "mating" },
        { text: "Avg Mating Day", value: "avg" },
      ],

      littersSireHeaders: [
        { text: "Litter ID", value: "name" },
        // { text: "Sire", value: "sire.name" },
        { text: "DoB", value: "dob" },
        { text: "Status", value: "status" },
        { text: "Puppies", value: "total_puppies" },
        { text: "Girls", value: "total_girls" },
        { text: "Boys", value: "total_boys" },
        // { text: "Matings", value: "mating" },
        // { text: "Avg Mating Day", value: "avg" },
      ],
    };
  },

  computed: {
    dog() {
      return this.$store.getters["lhl/dogs/get"];
    },
  },

  methods: {
    countPuppiesGender(gender, litter) {
      let puppies = litter.puppies.filter((c) => c.gender == gender);

      return puppies;
    },

    avgMatings(litter) {
      let sum = 0;

      litter.matings.forEach((mating) => {
        if (mating.day_number == null) {
          mating.day_number = 0;
        }
        sum += parseInt(mating.day_number);
      });

      let avg = 0;
      if (litter.matings.length > 0) {
        avg = sum / litter.matings.length;
      }

      return avg;
    },
  },
};
</script>
